<template>
  <div class="guide">
    <img src="https://cdn.cailu88.com/jingxuanshi/shareGuide/guide_step1.png" alt="">
    <img src="https://cdn.cailu88.com/jingxuanshi/shareGuide/guide_step2.png" alt="">
    <img src="https://cdn.cailu88.com/jingxuanshi/shareGuide/guide_step3.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'guide'
}
</script>

<style scoped lang="less">
.guide {
  width: 375px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  img {
    width:358px;
    margin-top: 12px;
  }
}
</style>
